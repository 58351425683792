<template>
  <div>
    <v-card>
      <v-card-title>Listado de contratos</v-card-title>
      <v-card-text>
        <botonera 
            :botones="botones" 
            alinear="right"
            @crear="dialog = !dialog"
            @cargar="upload = !upload"
            @eliminar="deleteAll"
        ></botonera>
      </v-card-text>
    </v-card>

    <datatable 
        :cabeceras="contratosListHeaders" 
        :items="contratosListBody" 
        titulo="Listado de contratos" 
        label="Escriba para buscar contratos" 
        icono="list" 
        color_icono="azul"
        :tachar="false"
        :acciones="['editar','eliminar']"
        @editar="editContrato($event)"
        @eliminar="deleteContrato($event)"
    ></datatable>

    <!-- Modal Nuevo Contrato -->
    <v-dialog
        v-model="dialog"
        width="800px"
        @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col cols="3">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="contrato.tipo_acuerdo"
                :items="tipo_acuerdos"
                item-text="nombre"
                item-value="id"
                label="Tipo de Acuerdo"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col> 
            <v-col cols="3">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="contrato.empresa"
                :items="empresas"
                item-text="razon_social"
                item-value="id"
                label="Cliente"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col>
            <v-col cols="3">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="contrato.wellboat"
                :items="wellboats"
                item-text="nombre"
                item-value="id"
                label="Wellboat"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field
                prepend-icon="mdi-numeric"
                placeholder="Horas"
                label="Horas"
                v-model="contrato.horas"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                prepend-icon="mdi-numeric"
                placeholder="Dolares"
                label="Dolares"
                v-model="contrato.dolares"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                prepend-icon="mdi-numeric"
                placeholder="Dias pago"
                label="Dias pago"
                v-model="contrato.dias_pago"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                prepend-icon="mdi-numeric"
                placeholder="Error ATS (%)"
                label="Error ATS (%)"
                v-model="contrato.error_ats_porc"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                prepend-icon="mdi-numeric"
                placeholder="Error Cos (%)"
                label="Error Cos (%)"
                v-model="contrato.error_cos_porc"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-btn
                @click="closeModal"
              >Cerrar</v-btn>
              <v-btn
                color="primary"
                @click="saveContrato"
              >Grabar</v-btn>
        </v-card-actions>
              
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <!-- Modal upload -->
    <v-dialog
      v-model="upload"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de contratos desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadContratos"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'
  export default {
    data: () => ({
      title: 'Nuevo Contrato',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      search: null,
      file: null,
      dialog: false,
      upload: false,
      empresas: [],
      contratoIndex: -1,
      latitud: `40°28'20,00"`,
      longitud: `3°33'39,00"`,
      tipo_acuerdos: [],
      wellboats: [],
      contrato: {
        id: 0,
        tipo_acuerdo: {},
        empresa: {},
        wellboat: {},
        horas: '00:00',
        dias_pago: 0,
        dolares: 0,
        error_ats_porc: 0,
        error_cos_porc: 0,
      },
      contratosListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Empresa',
          align: 'center',
          sortable: true,
          value: 'empresa.razon_social',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Tipo de acuerdo',
          align: 'center',
          sortable: true,
          value: 'tipo_acuerdo.nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Wellboat',
          align: 'center',
          sortable: true,
          value: 'wellboat.nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Horas',
          align: 'center',
          sortable: true,
          value: 'horas',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Dolares',
          align: 'center',
          sortable: true,
          value: 'dolares',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Dias pago',
          align: 'center',
          sortable: true,
          value: 'dias_pago',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Error ATS (%)',
          align: 'center',
          sortable: true,
          value: 'error_ats_porc',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Error COS-RTP (%)',
          align: 'center',
          sortable: true,
          value: 'error_cos_porc',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      contratosListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nuevo Contrato'},
        {boton:'cargar',tooltip:'Carga Masiva'},
        {boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadEmpresas();
      this.loadTipoAcuerdos();
      this.loadWellboats();
      this.loadContratos();
    },
    methods:{
        async loadEmpresas(){
            let url = `${this.base_url}empresas`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.empresas = response.data;
                this.contrato.empresa = this.empresas[0];
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadTipoAcuerdos(){
            let url = `${this.base_url}tipo_acuerdos`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.tipo_acuerdos = response.data;
                this.contrato.tipo_acuerdo = this.tipo_acuerdos[0];
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadWellboats(){
            let url = `${this.base_url}wellboats`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.wellboats = response.data;
                this.contrato.wellboat = this.wellboats[0];
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadContratos(){
            this.mostrarLoading('Cargando listado de contratos...')
            let url = `${this.base_url}contratos`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.contratosListBody = response.data;
                this.ocultarLoading()
            }).catch((error)=>{
                console.log(error);
                this.ocultarLoading()
            });
        },
        async saveContrato(){
            // validation
            this.errors = [];
            if(!this.contrato.tipo_acuerdo){
                this.errors.push("Debe indicar el tipo de acuerdo");
            }
            if(!this.contrato.wellboat){
                this.errors.push("Debe indicar wellboat");
            }
            if(!this.contrato.dolares){
                this.errors.push("Debe indicar dolares");
            }
            if(!this.contrato.dias_pago){
                this.errors.push("Debe indicar dias pago");
            }
            if(!this.contrato.error_ats_porc){
                this.errors.push("Debe indicar error ATS");
            }
            if(!this.contrato.error_cos_porc){
                this.errors.push("Debe indicar error COS-RPT");
            }
            if(this.contrato.empresa == null){
                this.errors.push("Debe seleccionar una empresa");
            }
            if(this.errors.length > 0){
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
                return;
            }
            let url = `${this.base_url}contratos`;
            let contratoData = {
                'tipo_acuerdos_id': this.contrato.tipo_acuerdo.id,
                'empresas_id': this.contrato.empresa.id,
                'wellboats_id': this.contrato.wellboat.id,
                'horas': this.contrato.horas,
                'dolares': this.contrato.dolares,
                'dias_pago': this.contrato.dias_pago,
                'error_ats_porc': this.contrato.error_ats_porc,
                'error_cos_porc': this.contrato.error_cos_porc,
            };
            if(this.contrato.id > 0){
                url = url + "/" + this.contrato.id;
                await this.axios.put(url, contratoData, this.headers).then((response)=>{
                let index = this.contratosListBody.map(contrato => {
                    return contrato.id;
                }).indexOf(this.contrato.id);
                this.contratosListBody.splice(index, 1, response.data);
                this.showSnackBar(false);
                }).catch((error)=>{
                console.log(error);
                this.showSnackBar(true);
                });
            }else{
                await this.axios.post(url, contratoData, this.headers).then((response)=>{
                this.contratosListBody.push(response.data);
                this.showSnackBar(false);
                }).catch((error)=>{
                this.errors = error.response.data;
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
                });
            }
            this.cleanForm();
        },
        editContrato(item) {
            this.contratoIndex = this.contratosListBody.indexOf(item);
            this.contrato = Object.assign({}, item);
            this.dialog = true;
            this.title = "Editar Contrato";
        },
        async deleteContrato(item) {
            this.$confirm('Desea borrar este contrato?').then(res => {
                if(res){
                let url = `${this.base_url}contratos/${item.id}`;
                this.axios.delete(url, this.headers).then((response)=>{
                    console.log(response);
                    const index = this.contratosListBody.indexOf(item);
                    this.contratosListBody.splice(index, 1);
                    this.showSnackBar(false);
                }).catch((error)=>{
                    this.validation_message = error;
                    this.showSnackBar(true);
                });
                }
            });
        },
        showSnackBar(error){
            if(error){
                this.color = 'red';
            }else{
                this.color = 'green';
                this.validation_message = "Ejecutado exitosamente!";
            }
            this.snackbar = true;
        },
        closeModal(){
            this.cleanForm();
            this.dialog = false;
            this.error = null;
            this.title = "Nuevo Contrato";
        },
        cleanForm(){
            this.contrato.id = 0;
            this.contrato.nombre = "";
            this.contrato.latitud = "";
            this.contrato.longitud = "";
            this.contrato.empresa = null;
            this.dialog = false;
        },
        async uploadContratos(){
            this.headers.headers['Content-Type'] = "multipart/form-data";
            let url = `${this.base_url}uploads/contratos`;
            let formData = new FormData();
            formData.append("excel", this.file);
            let response = null;
            try{
                response = await this.axios.post(url, formData, this.headers);
                this.file = null;
                this.upload = false;
                this.loadContratos();
                this.showSnackBar(false);
            }catch(error){
                response = error.response;
                let msg = response.data.data;
                this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
                this.showSnackBar(true);
            }
        },
        async deleteAll(){
            this.$confirm('Desea borrar todos los datos?').then(res => {
                if(res){
                    let url = `${this.base_url}truncar/contratos`;
                    this.axios.get(url, this.headers).then((response)=>{
                        console.log(response);
                        this.loadContratos();
                        this.showSnackBar(false);
                    }).catch((error)=>{
                        this.validation_message = error;
                        this.showSnackBar(true);
                    });
                }
            });
        },
        ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
        ...mapState(['loading', 'base_url', 'headers']),
    },
    components:{
      //'datatable':DataTable
    }
  }
</script>